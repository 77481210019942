import React, { useState } from 'react'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap'
import style from './style.module.css';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import Alert, { I_AlertObject } from '../Alert';
import { logout } from './../../store/slices/userSlice';

type T_Props = {
    children?: any
}

const Menu = ({
    children
}: T_Props) => {

    const userInfo = useAppSelector(state => state.user.userInfo);
    const dispatch = useAppDispatch();

    const [_alert, setAlert] = useState<null | I_AlertObject>(null);

    const confirmLogout = () => {
        setAlert({
            isOpen: true,
            title: "¿Desea cerrar la sesión?",
            type: "question",
            submitButton: {
                value: "Sí, cerrar",
                onClick: () => {
                    dispatch(logout())
                }
            },
            closeButton: { value: "No, cancelar" }
        })
    }

    return (
        <>
            <Alert isOpen={!!(_alert?.isOpen)}{..._alert} onClosed={() => { setAlert(null) }} />

            <UncontrolledDropdown className={style["menu"]}>
                <DropdownToggle caret tag="div" className='d-flex align-items-center'>
                    {children}
                </DropdownToggle>
                <DropdownMenu className='shadow-sm mt-1 py-3'>
                    <DropdownItem header style={{ whiteSpace: "normal" }} className="pb-4">
                        <p style={{ width: "300px" }} className="text-center mb-0 pb-3 border-bottom opacity-75">
                            {userInfo?.user}
                        </p>
                    </DropdownItem>
                    <DropdownItem header >
                        <p className='fw-normal'>
                            <b className='d-block'>Cargo:</b>
                            <span>{userInfo?.cargo}</span>
                        </p>
                        <p className='fw-normal'>
                            <b className='d-block'>Correo:</b>
                            <span>{userInfo?.email}</span>
                        </p>
                        <p className='fw-normal'>
                            <b className='d-block'>Identificación:</b>
                            <span>{userInfo?.dni}</span>
                        </p>
                    </DropdownItem>
                    <DropdownItem disabled tag="div" style={{ "pointerEvents": "initial" }} className="mt-3 text-center">
                        <Button color='dark' onClick={confirmLogout} size='sm'>Cerrar sesión</Button>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    )
}

export default Menu