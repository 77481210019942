import React from 'react'

const Footer = () => {
  return (
    <div className='footer text-muted small pt-3 text-center opacity-75'>
      <small>Corporación Universitaria Rafael Núñez</small> | 
      <small> Institución Universitaria</small> | 
      <small> Vigilada Mineducación</small>
    </div>
  )
}

export default Footer