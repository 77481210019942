import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import ErrorHandler from './components/ErrorHandler';
import './styles/bootstrap.min.css';

declare global {
  interface Window {
    location: Location;
    [x: string]: any;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <ErrorHandler>
        <App />
      </ErrorHandler>
    </Provider>
  // </React.StrictMode>
);
