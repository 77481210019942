import React from 'react'
import style from './style.module.css';
import { useAppSelector } from '../../hooks/useAppSelector'

const Avatar = () => {

    const userInfo = useAppSelector(state => state.user.userInfo);

    if (!userInfo) {
        return null
    }

    return (
        <div className={style['avatar']}>
            <div>
                {userInfo.photo ?
                    <img src={userInfo.photo} />
                    :
                    userInfo.email[0]
                }
            </div>
        </div>
    )
}

export default Avatar