import { Calendar2 } from '../icons';
import logo from './../../img/logocurnh-80h.png';
import Avatar from './Avatar';
import Menu from './Menu';
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <div className='header'>
            <div className='brand container-fluid'>
                <img src={logo} alt="Plan" />
            </div>
            <div className='container-fluid d-flex justify-content-between flex-grow-1 align-items-center'>
                <div>
                    <h4 className='mb-0  mx-auto'>
                        <Link to="/" className='link-dark text-decoration-none'>
                            Planus
                        </Link>
                    </h4>
                </div>
                <div className='ms-auto d-flex gap-4 align-items-center'>
                    <div>
                        <Link to={"/calendario"} className='btn btn-link link-dark text-decoration-none d-flex gap-2 align-items-center'>
                            <Calendar2 size={15} /> Calendario
                        </Link>
                    </div>
                    <div>
                        <Menu>
                            <Avatar />
                        </Menu>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header