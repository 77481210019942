import { AnyAction, combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit'
import localStorageService from '../services/localStorageService';
import { setOtherAxiosConfig } from '../services/axiosService';
import userSlice, { logout, setUnauthorized } from './slices/userSlice';
import estrategiesSlice from './slices/estrategiesSlice';

const slices = {
    user: userSlice,
    estrategies: estrategiesSlice
}

const combinedSlices = combineReducers(slices);

const reducer = (state: any, action: AnyAction) => {
    if (logout.match(action) || (setUnauthorized.match(action) && !!(action.payload))) {
        localStorageService.deleteItems(["user", "token"]);
        state = undefined;
    }
    return combinedSlices(state, action)
}

const store = configureStore({ reducer });

export const startUserConfigurations = () => {

    setOtherAxiosConfig({
        validateStatus: (status: number) => {
            if (status === 401) {
                store.dispatch(setUnauthorized("Su sesión ha expirado"));
            }
            return status >= 200 && status < 300; // default
        }
    });

}

startUserConfigurations();

export type T_AppState = ReturnType<typeof store.getState>
export type T_AppDispatch = typeof store.dispatch;

export default store;
