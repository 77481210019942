import { Spinner } from 'reactstrap';

const FallbackComponen1 = ({ color = "dark" }: { color?: string }) => {
    return (
        <div className='w-100 h-100 d-flex justify-content-center align-items-center position-absolute'>
            <Spinner color={color} animation="border" role="status" />
        </div>
    );
};

export default FallbackComponen1;