/**
 * @method POST
 * @params { token, confia: 0 }
 */
export const LOGIN = "auth";
/**
 * @method GET
 * @params /[{type}]
 * @param type? - can be 1 for get A and D roles; 2 for get only B roles
 */
export const ROLS = `roles`;

/*---------------------------------------------*/
// GOALS
/*---------------------------------------------*/

/**
 * @method GET
 */
export const GET_OWN_GOALS = "meta/usuario"

/**
 * @method GET
 * @param indicator_id 
 */
export const GET_GOALS_BY_INDICATOR = "meta/indicadores/"

/*---------------------------------------------*/
// ACTIVITIES
/*---------------------------------------------*/

/**
 * @method GET
 * @params /goal_id
 */
export const GET_GOAL_ACTIVITY = "activity/"

/**
 * @method POST
 * @params {nomb_act, desc_act, id_meta, peso_act, fini_act, ffin_act, [anexo_act], [archivos]}
 */
export const SAVE_GOAL_ACTIVITY = "activity"

/**
 * @method PUT
 * @description All parameters are optional, but need at least 1
 * @params {nomb_act, desc_act, id_meta, peso_act, fini_act, ffin_act, id_acti }
 */
export const UPDATE_GOAL_ACTIVITY = "activity"

/**
 * @method PUT
 * @description All parameters are optional, but need at least 1
 * @params {[anexo_act], [archivos], id_acti}
 */
export const ADD_ACTIVITY_ATTACHMENT = "activity"

/**
 * @method PUT
 * @params {indexEliminar: Array, id_acti}
 */
export const DELETE_ACTIVITY_ATTACHMENT = "activity"

/**
 * @method DELETE
 * @param activity_id  
 */
export const DELETE_GOAL_ACTIVITY = "activity/"


/*---------------------------------------------*/
// COLLABORATOR
/*---------------------------------------------*/

/**
 * @method GET
 */
export const GET_COLLABORATOR_FROM_LEAD = "responsable/colaboradores"

/**
 * @method POST
 * @params {responsables[0].id_rc, indicadores[0].id_indi}
 */
export const ASSOCIATE_COLLABORATOR = "responsable/indicadores"

/*---------------------------------------------*/
// CALENDAR
/*---------------------------------------------*/

/**
 * @method GET
 */
export const GET_CALENDAR_PERIOD = "periodo"

/**
 * @method POST
 * @params {fecha_cale, desc_cale, id_periodo}
 */
export const SAVE_CALENDAR_EVENT = "periodo/calendario"

/**
 * @method PUT
 * @params {fecha_cale, desc_cale, id_periodo, id_cale}
 */
export const UPDATE_CALENDAR_EVENT = "periodo/calendario"

/**
 * @method DELETE
 * @param /id_cale
 */
export const DELETE_CALENDAR_EVENT = "periodo/calendario/"
