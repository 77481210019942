import { PayloadAction, createSlice, } from "@reduxjs/toolkit";
import { I_StrategyState } from "../../interfaces/store.interface";

const name = "estrategies";

const initialState = (): I_StrategyState => ({
    list: null,
    selectedStrategy: null
})

const estrategiesSlice = createSlice({
    name,
    initialState,
    reducers: {
        setList: (state, action: PayloadAction<any>) => {
            state.list = action.payload;
        },
        setSelectedStrategy: (state, action: PayloadAction<any>) => {
            state.selectedStrategy = action.payload && { ...(state.selectedStrategy || {}), ...action.payload }
        },
    }
})

export default estrategiesSlice.reducer;

export const { setList, setSelectedStrategy } = estrategiesSlice.actions;
