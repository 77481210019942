import { PayloadAction, createAction, createAsyncThunk, createSlice, } from "@reduxjs/toolkit";
import localStorageService from "../../services/localStorageService";
import { AXIOS_REQUEST, setTokenForAxiosRequest } from "../../services/axiosService";
import { LOGIN } from "../../services/endPointsService";
import { I_UserState } from "../../interfaces/store.interface";

const name = "user";

const initialState = (): I_UserState => ({
    userInfo: localStorageService.getItem("user"),
    unauthorized: "",
})

export const login = createAsyncThunk(`${name}/login`, async (credential: string, { rejectWithValue }) => {
    let data = { token: credential, confia: 0 }

    let resp = await AXIOS_REQUEST(LOGIN, "post", data).catch(({ response }) => ({ ...response.data, error: true }))

    if (!resp || !(resp?.data)) return rejectWithValue("Parece que no tiene permisos para ingresar");

    const { token, ...user } = resp.data;

    return { token, user };

});

export const logout = createAction(`${name}/logout`)

export const setUnauthorized = createAction<string | undefined | false | null>(`${name}/setUnauthorized`)

const userSlice = createSlice({
    name,
    initialState,
    reducers: {
        setUserInfo: (state, action: PayloadAction<any>) => {
            state.userInfo = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
            const { user, token } = action.payload;

            localStorageService.setItems({
                "user": JSON.stringify(user),
                token
            });
            setTokenForAxiosRequest("");
            state.userInfo = user;
            // userSlice.actions.setUserInfo(user);
        });
        builder.addCase(login.rejected, (state) => {
            state.unauthorized = "Parece que no tiene permisos para ingresar";
        })
        builder.addCase(setUnauthorized, (state, action: PayloadAction<any>) => {
            state.unauthorized = action.payload;
            state.userInfo = null;
        })
    },
})

export default userSlice.reducer;

export const {
    setUserInfo
} = userSlice.actions;
