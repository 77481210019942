import { lazy, Suspense } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import lazyLoaderComponents from './services/lazyLoadingService';
import { useAppSelector } from './hooks/useAppSelector';
import { useAppDispatch } from './hooks/useAppDispatch';
import FallbackComponen1 from './components/Loader/FallbackComponen1';
import { CloseButton, Toast, ToastBody } from 'reactstrap';
import { setUnauthorized } from './store/slices/userSlice';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/App.css';

const Login = lazy(lazyLoaderComponents(() => import(/* webpackChunkName: "Login" */ './screens/Login')));
const Root = lazy(lazyLoaderComponents(() => import(/* webpackChunkName: "Root" */ './screens/Root')));

function App() {

  const user = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();

  if (!!(user.unauthorized) || !(user.userInfo)) {
    return <Suspense fallback={<FallbackComponen1 />}>
      {user.unauthorized &&
        <div className='position-absolute w-100' style={{ zIndex: 1 }}>
          <Toast className='border-0 bg-danger text-white my-4 mx-auto'>
            <ToastBody className='d-flex justify-content-between'>
              <div>{user.unauthorized}</div>
              <div><CloseButton variant='white' onClick={() => { dispatch(setUnauthorized()) }} /></div>
            </ToastBody>
          </Toast>
        </div>
      }
      <Login />
    </Suspense>
  }

  return (<>
    <Router>
      <Header />
      <div className='main'>
        {/* <Sidebar /> */}
        <div className='content container-xl'>
          <Suspense fallback={<main><FallbackComponen1 /></main>}>
            <Root />
          </Suspense>
          <Footer />
        </div>
      </div>
    </Router>
  </>);
}

export default App;
